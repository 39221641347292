export const scoreRules = {
  ruleName: [{ required: true, message: '输入方案名称', trigger: 'blur' }],
  correlationIds: [{ required: true, message: '选择关联对象', trigger: 'blur' }],
  danci: [{ required: true, message: '选择单次消费 ', trigger: 'blur' }],
  chuzhikabenjin: [
    { required: true, message: '选择储值卡-本金 ', trigger: 'blur' }
  ],
  chuzhikazengsong: [
    { required: true, message: '选择储值卡-赠金 ', trigger: 'blur' }
  ],
  yuebenjin: [
    { required: true, message: '选择账户余额-本金 ', trigger: 'blur' }
  ],
  yuezengsong: [
    { required: true, message: '选择账户余额-赠金 ', trigger: 'blur' }
  ],
  youxianbenjin: [
    { required: true, message: '选择有限次卡-购买 ', trigger: 'blur' }
  ],
  youxianzengsong: [
    { required: true, message: '选择有限次卡-赠送', trigger: 'blur' }
  ],
  buxianbenjin: [
    { required: true, message: '选择无限次卡-购买 ', trigger: 'blur' }
  ],
  buxianzengsong: [
    { required: true, message: '选择无限次卡-赠送', trigger: 'blur' }
  ],
  duihuan: [
    { required: true, message: '选择兑换券', trigger: 'blur' }
  ],
  zhekoubenjin: [{ required: true, message: '选择折扣卡-购买', trigger: 'blur' }],
  zhekouzengsong: [{ required: true, message: '选择折扣卡-赠送', trigger: 'blur' }],
  sharetype_xh: [{ required: true, message: '选择消耗业绩多人分配', trigger: 'blur' }],
  sharetype_sale: [{ required: true, message: '选择销售业绩多人分配 ', trigger: 'blur' }],
  buyYue:[{ required: true, message: ' 选择储值卡', trigger: 'blur' }],
  buyChuzhiKa: [{ required: true, message: ' 选择储值卡', trigger: 'blur' }],
  buyZhekouKa: [{ required: true, message: '选择折扣卡 ', trigger: 'blur' }],
  buyYouxian: [{ required: true, message: '选择有限次卡 ', trigger: 'blur' }],
  buyWuxian: [{ required: true, message: '选择无限次卡 ', trigger: 'blur' }],
  buyDuihuan: [{ required: true, message: '选择兑换券 ', trigger: 'blur' }]
}
