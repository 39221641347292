<template>
  <div class="duoren-fenpei-dialog">
    <div class="title">选择人数</div>
    <el-select size="small" @change="handlePnmChange" v-model="ruleForm.pnum" class="m-2" placeholder="选择人数">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
    </el-select>

    <div class="input-wrap">
      <el-scrollbar height="100px">
        <template :key="item" v-for="(item, index) in ruleForm.pnum">
          <div class="pnum-input">
            <div class="tips"> 第{{ index + 1 }}位</div>
            <el-input size="small" v-model="yejiForm[index].value">
              <template #suffix>
                | %
              </template>
            </el-input>
          </div>
        </template>
      </el-scrollbar>
    </div>

    <div class="save">
      <el-button @click="handleSaveClick" color="#793EEA" size="small" type="primary">保存</el-button>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import { ElMessage } from 'element-plus'


export default defineComponent({
  props: {

  },
  components: {

  },
  setup(props, { emit }) {

    const options = ref([
      {
        label: '2个人',
        value: 2
      },
      {
        label: '3个人',
        value: 3
      },
      {
        label: '4个人',
        value: 4
      },
      {
        label: '5个人',
        value: 5
      },
    ])

    const ruleForm = reactive({
      pnum: 2,
    })


    const yejiForm = ref([
      { value: '' },
      { value: '' },

    ])

    watch(yejiForm, (n) => {
      emit('yejiFormChange', [...n])
    }, {
      deep: true
    })

    const handleSaveClick = () => {
      let sum = 0
      let flag = false
      yejiForm.value.forEach(item => {
        sum += Number(item.value)
        if (item.value <= 0) {
          flag = true
        }
      })
      if (sum != 100) {
        ElMessage({
          message: '占比加起来必须为100！',
          type: 'warning',
        })

        return
      }

      if (flag) {
        ElMessage({
          message: '参数值不合法!',
          type: 'warning',
        })

        return
      }
      emit('changeYejiSave')
    }

    const handlePnmChange = (value) => {
      yejiForm.value = []
      for (let i = 0; i < value; i++) {
        yejiForm.value.push({
          value: ''
        })
      }
    }

    return {
      ruleForm,
      handlePnmChange,
      options,
      yejiForm,
      handleSaveClick
    }

  }
})
</script>

<style scoped lang="less">
.duoren-fenpei-dialog {
  position: absolute;
  width: 290px;
  padding: 20px;
  font-size: 12px;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  left: 112px;
  top: 40px;

  &:deep(.el-select) {
    width: 100%;
  }

  .save {
    margin-top: 20px;

    &:deep(.el-button) {
      width: 100%;
    }
  }

  .title {
    color: rgba(80, 80, 80, 1);
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 8px;
  }

  .input-wrap {
    margin-top: 20px;

    .pnum-input {
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .tips {
        margin-right: 10px;
        color: rgba(121, 72, 234, 1);
        min-width: 45px;
        word-spacing: normal;
      }
    }
  }


}
</style>




