import { request4 } from '@/service/request4'

export function getRankList(cid) {
  return request4({
    url: `/manager/job/level/job-level/list/${cid}`,
    method: 'post',
  })
}

export function getScoreRuleList(data) {
  return request4({
    url: `/achievementRule/web/getAchievementRuleList`,
    method: 'post',
    data
  })
}

export function addScoreRule(obj) {
  return request4({
    url: `/achievementRule/web/createAchievementRule`,
    method: 'post',
    data:obj
  })
}

export function getScrollRuleDetail(id) {
  return request4({
    url: `/achievementRule/web/getAchievementRule?id=${id}`,
    method: 'get',
  })
}

export function getTichengList(data) {
  return request4({
    url: `/commissionRule/web/getCommissionRuleList`,
    method: 'post',
    data
  })
}

//删除个人提成规则
export function removeTichengRule(id) {
  return request4({
    url: `/commissionRule/web/deleteCommissionRule?id=${id}`,
    method: 'DELETE',
  })
}

//创建个人提成
export function addTichengRule(obj) {
  return request4({
    url: `/commissionRule/web/createCommissionRule`,
    method: 'post',
    data:obj
  })
}

//创建门店提成
export function createShopCommissionRule(obj) {
  return request4({
    url: `/shopCommissionRule/web/createShopCommissionRule`,
    method: 'post',
    data:obj
  })
}

export function getRoleList(companyid) {
  return request4({
    url: `/manager/job/level/job-level/list/${companyid}`,
    method: 'post',
  })
}

export function getOldBindRoleList(tid) {
  return request4({
    url: `/manager/money/ticheng-roles/${tid}`,
    method: 'post',
  })
}

export function updateBindTcRule(roleid,tid,kind,shopid) {
  return request4({
    url: `/manager/money/deal-bind-role-ticheng/${roleid}/${tid}/${kind}/${shopid}`,
    method: 'post',
  })
}

//获取个人提成详情
export function getTcDetail(id) {
  return request4({
    url: `/commissionRule/web/getCommissionRule?id=${id}`,
    method: 'get',
  })
}

//编辑个人提成规则
export function updateTcRule(obj) {
  return request4({
    url: `/commissionRule/web/editCommissionRule`,
    method: 'PUT',
    data:obj
  })
}

export function editAchievementRule(obj) {
  return request4({
    url: `/achievementRule/web/editAchievementRule`,
    method: 'PUT',
    data:obj
  })
}

export function getCardList2(kind) {
  return request4({
    url: `/manager/money/get-card-item/${kind}`,
    method: 'post',
  })
}

export function getAllStaffList(cid) {
  return request4({
    url: `/manager/admin/all-admin-users/${cid}`,
    method: 'post',
  })
}

export function getRechargeList(companyId) {
  return request4({
    url: `/manager/customer/cash-rules/${companyId}/1`,
    method: 'post',
  })
}

export function getOldTichengList(shopId,kind) {
  return request4({
    url: `/manager/money/ticheng-bind-emps/${shopId}/${kind}`,
    method: 'post',
  })
}

export function addZhznegdianTicheng(obj) {
  return request4({
    url: `/manager/admin/new-shop-rule`,
    method: 'post',
    data:obj
  })
}

//门店提成列表
export function getShopCommissionRuleList(data) {
  return request4({
    url: `/shopCommissionRule/web/getShopCommissionRuleList`,
    method: 'post',
    data
  })
}

//门店提成详情
export function getShopCommissionRule(id) {
  return request4({
    url: `/shopCommissionRule/web/getShopCommissionRule?id=${id}`,
    method: 'get'
  })
}

//编辑门店提成规则
export function editShopCommissionRule(obj) {
  return request4({
    url: `/shopCommissionRule/web/editShopCommissionRule`,
    method: 'PUT',
    data:obj
  })
}

export function distribution(data) {
  return request4({
    url: `/achievementRule/web/distribution`,
    method: 'post',
    data
  })
}

//删除门店提成规则
export function removeZhengdianTicheng(id) {
  return request4({
    url: `/shopCommissionRule/web/deleteShopCommissionRule?id=${id}`,
    method: 'DELETE'
  })
}



